import React from "react"
import "./contact-forms.component.style.scss"

// import ContactUsComponent from "../../components/contact-us/contact-us.component"

export default class ContactFormsComponent extends React.Component{
    render(){
        return(
            <div className="contact-forms">
                <div className="contact-forms-holder">
                    <h1 className="contact-forms-title">Fale Conosco</h1>
                    <h2 className="contact-input-label">Nome</h2>
                    <input className="contact-forms-input"></input>
                    <h2 className="contact-input-label">Email</h2>
                    <input className="contact-forms-input"></input>
                    <h2 className="contact-input-label">Mensagem</h2>
                    <textarea className="contact-forms-input"></textarea>
                </div>
            </div>
        )
    }
}