import React from "react"


import TopBarContainer from "../containers/topbar/topbar.container"
import FooterContainer from "../containers/footer/footer.container"
import SidebarContainer from "../containers/sidebar/sidebar.container"


export default class DefaultLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {showSidebar: false }
  }

  toggleSidebar() {
    this.setState({ showSidebar: !this.state.showSidebar })
  }


  render() {
    return (
      <div>
        <TopBarContainer toggleSidebar={this.toggleSidebar.bind(this)} ></TopBarContainer>
          {this.props.children}
        <FooterContainer></FooterContainer>
        <SidebarContainer  toggleSidebar={this.toggleSidebar.bind(this)} showSidebar={this.state.showSidebar}></SidebarContainer>
      </div>
    )
  }
}

