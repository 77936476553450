import React from "react"
import "./botao-email.component.style.scss"
import email from "../../../images/Email2.svg"

export default class BotaoEmailComponent extends React.Component{
    render(){
        return(
            <div className="botao-email-holder">
                <a className="botao-email-link" target="_blank" href={"mailto:"+this.props.email}>
                    <div className="botao-email">
                        <img className="botao-email-imagem" alt="Email symbol" src={email}></img>
                    </div>
                </a>
            </div>
        )
    }
}