import React from "react"
import "./footer.container.style.scss"

import ContactUsComponent from "../../components/contact-us/contact-us.component"
import ContactFormsComponent from "../../components/contact-forms/contact-forms.component"

export default class FooterContainer extends React.Component{
    render(){
        return(
            <div className="footer">
                <div className="footer-content">
                    <ContactUsComponent
                        site="https://sflabs.com.br/"
                        email="contato@sflabs.com.br"
                        telefone="5511957748642"
                        endereco="SF Labs, R. Alfredo Lopes, 1717 - E8 - Jardim Macarengo, São Carlos - SP, 13560-460"
                        facebook=""
                        linkedin="sflabs-brazil"
                    ></ContactUsComponent>
                    {/* <ContactFormsComponent></ContactFormsComponent> */}
                </div>
            </div>
        )
    }
}