import React from "react"
import "./botoes-holder.component.style.scss"
import BotaoEmailComponent from "../botao-email/botao-email.component"
import BotaoFacebookComponent from "../botao-facebook/botao-facebook.component"
import BotaoLinkedinComponent from "../botao-linkedin/botao-linkedin.component"
import BotaoWhatsappComponent from "../botao-whatsapp/botao-whatsapp.component"

export default class BotoesHolderComponent extends React.Component{
    render(){
        return(
            <div className="botoes-holder">
                <BotaoEmailComponent email={this.props.email}></BotaoEmailComponent>
                <BotaoFacebookComponent facebook={this.props.facebook}></BotaoFacebookComponent>
                <BotaoLinkedinComponent linkedin={this.props.linkedin}></BotaoLinkedinComponent>
                <BotaoWhatsappComponent whatsapp={this.props.whatsapp}></BotaoWhatsappComponent>
            </div>
        )
    }
}