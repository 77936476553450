import React from "react"
import "./botao-linkedin.component.style.scss"
import linkedin from "../../../images/Linkedin.svg"

export default class BotaoLinkedinComponent extends React.Component{
        
    constructor(props) {
        super(props)
        this.state = { link: "" }
    }

    componentDidMount() {
        let link = this.checkForMobile(this.props.linkedin)
        this.setState({ link: link })
    }

    render(){
        return(
            <div className="botao-linkedin-holder">
                <a className="botao-linkedin-link" target="_blank" href={this.state.link}>
                    <div className="botao-linkedin">
                        <img className="botao-linkedin-imagem" alt="Linkedin symbol" src={linkedin}></img>
                    </div>
                </a>
            </div>
        )
    }

    checkForMobile = lnkd => {
        if (
          window.navigator.userAgent.match(/Android/i)|| 
          window.navigator.userAgent.match(/webOS/i)||
          window.navigator.userAgent.match(/iPhone/i)|| 
          window.navigator.userAgent.match(/iPad/i)|| 
          window.navigator.userAgent.match(/iPod/i)|| 
          window.navigator.userAgent.match(/BlackBerry/i)|| 
          window.navigator.userAgent.match(/Windows Phone/i)
        ) {
          return ``
        } else {
          return `https://www.linkedin.com/company/${lnkd}`
        }
    }
}