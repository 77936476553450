import React from "react"
import "./botao-facebook.component.style.scss"
import facebook from "../../../images/Facebook.svg"

export default class BotaoFacebookComponent extends React.Component {
    
    constructor(props) {
        super(props)
        this.state = { link: "" }
    }

    componentDidMount() {
        let link = this.checkForMobile(this.props.facebook)
        this.setState({ link: link })
    }
    
    render() {
        return (
            <div className="botao-facebook-holder">
                <a className="botao-facebook-link" target="_blank" href={this.state.link}>
                    <div className="botao-facebook">
                        <img className="botao-facebook-imagem" alt="Facebook symbol" src={facebook}></img>
                    </div>
                </a>
            </div>
        )
    }

    checkForMobile = fb => {
        if (
          window.navigator.userAgent.match(/Android/i)|| 
          window.navigator.userAgent.match(/webOS/i)||
          window.navigator.userAgent.match(/iPhone/i)|| 
          window.navigator.userAgent.match(/iPad/i)|| 
          window.navigator.userAgent.match(/iPod/i)|| 
          window.navigator.userAgent.match(/BlackBerry/i)|| 
          window.navigator.userAgent.match(/Windows Phone/i)
        ) {
          return ""
        } else {
          return ""
        }
    }
}