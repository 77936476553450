import React from 'react'
import './topbar.container.style.scss'

export default class TopBarContainer extends React.Component {
    constructor(props){
        super(props)
        this.state = {logoClass: "", topbarClass: ""}
    }
    componentDidMount() {
        window.addEventListener('scroll', this.handleScroll.bind(this));
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.handleScroll);
    }

    handleScroll() {
        if( document.documentElement.scrollTop > 80){
            this.setState({topbarClass: "topbar__shadow", logoClass: "logo_holder__mini"})
        }
        if( document.documentElement.scrollTop  < 10){
            this.setState({topbarClass: "", logoClass: ""})
        }
    }

    render() {
        return (
            <div className={"topbar "+this.state.topbarClass}>
                <div className="topbar_content">
                    <div className="topbar_item_holder">
                        <a href="/" className="topbar_item" target="_blank"> Home </a>
                    </div><div className="topbar_item_holder">
                        <a href="modulos" target="_blank" className="topbar_item"> Módulos </a>
                    </div>
                    <a className={"logo_holder "+this.state.logoClass} href="/">
                        <svg className="logo" viewBox="0 0 250 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M148.181 0L101.501 0.318848L0.318848 101.5L0 148.181L101.819 250L148.499 249.681L249.681 148.5L250 101.819L148.181 0ZM108.299 16.5742L125.015 16.46L28.2739 113.201L19.9731 104.9L108.299 16.5742ZM170.271 125.545L125.545 170.271L79.729 124.454L124.455 79.729L170.271 125.545ZM135.981 68.2026L144.554 59.6294L210.198 125.273L201.625 133.847L135.981 68.2026ZM114.019 181.797L105.446 190.371L39.8013 124.727L48.375 116.153L114.019 181.797ZM16.3481 141.475L16.4644 124.443L105.445 213.424L181.797 137.072L190.099 145.373L105.173 230.299L16.3481 141.475ZM141.701 233.425L124.985 233.54L221.726 136.799L230.026 145.1L141.701 233.425ZM233.536 125.557L144.555 36.5752L68.2026 112.928L59.9014 104.626L144.827 19.7007L233.652 108.525L233.536 125.557Z" fill="#333333" />
                        </svg>
                    </a>
                    <div className="topbar_item_holder">
                        <a href="/" target="_blank" className="topbar_item"> Funcionamento </a>
                    </div><div className="topbar_item_holder">
                        <a href="/" target="_blank" className="topbar_itembot"> Contato </a>
                    </div>
                    <div className="sticky">
                        <div className="menu_btn" onClick={() => { this.props.toggleSidebar() }}>
                            <div className="menu_btn_burger"></div>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}