import React from "react"
import "./contact-us.component.style.scss"
import BotoesHolderComponent from "../botões-redes/botoes-holder/botoes-holder.component"

// import ContactUsComponent from "../../components/contact-us/contact-us.component"

export default class ContactUsComponent extends React.Component{
    
    constructor(props) {
        super(props)
        this.state = { link: "" }
    }

    componentDidMount() {
        let link = this.checkForApple(this.props.endereco)
        this.setState({ link: link })
    }

    render(){
        return(
            <div className="contact-us">
                <div className="contact-us-text">
                    <a target="_blank" className="contact-us-title" href={this.props.site}>
                        <h1 className="contact-us-margin">SF Labs</h1>
                    </a>
                    <a target="_blank" className="contact-us-data" href={"mailto:"+this.props.email}>
                        <p className="contact-us-margin">
                            Email: {this.props.email}
                        </p>
                    </a>    
                    <a target="_blank" className="contact-us-data" href={"tel:+"+this.props.telefone}>
                        <p className="contact-us-margin">
                            Telefone: (11) 95774-8642
                        </p>
                    </a>
                    <a target="_blank" className="contact-us-data" href={this.state.link}>
                        <p className="contact-us-margin">
                            Endereço: R. Alfredo Lopes, 1717 - E8 - Jardim Macarengo,<br></br>São Carlos
                        </p>
                    </a>
                    <a className="contact-us-data" href="/politica">
                        <p className="contact-us-politica">
                            Política de privacidade
                        </p>
                    </a>
                </div>
                <BotoesHolderComponent
                    email={this.props.email}
                    facebook={this.props.facebook}
                    linkedin={this.props.linkedin}
                    whatsapp={this.props.telefone}
                ></BotoesHolderComponent>
            </div>
        )
    }

    checkForApple = maps => {
        if (
          window.navigator.userAgent.match(/iPhone/i)|| 
          window.navigator.userAgent.match(/iPad/i)|| 
          window.navigator.userAgent.match(/iPod/i)
        ) {
          return `http://maps.apple.com/?q=${maps}`
        } else {
          return `https://google.com/maps/?q=${maps}`
        }
    }
}